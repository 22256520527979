// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/login/Login.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/login/Login.tsx");
}
// REMIX HMR END

import { Alert, AlertDescription, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Box, Button, Container, FormControl, FormErrorMessage, FormLabel, Heading, HStack, Input, Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Stack, Text, useColorMode, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { useFetcher, useNavigate } from "@remix-run/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { DealbunnyIcon, DealbunnyIconNoGradient } from "../customIcons";
import { OAuthButtonGroup } from "./OAuthButtonGroup";
import { PasswordField } from "./PasswordField";
import loginContainer from "~/styles/loginStyle.css";
import { useAuth } from "~/utils/userUtils";
export const links = () => [{
  rel: "stylesheet",
  href: loginContainer
}];
const validationSchema = Yup.object({
  email: Yup.string().email("E-Mail ungültig").required("Pflichtfeld"),
  password: Yup.string().min(6, "Minimum 6 Zeichen").required("Pflichtfeld")
});
export const Login = ({
  isModal = false,
  onClose: onCloseModal
}) => {
  _s2();
  var _s = $RefreshSig$();
  const {
    user,
    login,
    logout
  } = useAuth();
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  const cancelRef = useRef(null);
  const [isOpenReset, setIsOpenReset] = useState(false);
  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [text, setText] = useState("");
  const [buttons, setButtons] = useState(<></>);
  const navigate = useNavigate();
  const {
    colorMode,
    toggleColorMode
  } = useColorMode();
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const loginFetcher = useFetcher();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [countdown, setCountdown] = useState(null);
  const [currentPath, setCurrentPath] = useState("");
  const logoutHandler = e => {
    e.preventDefault();
    fetch("/logout").then(response => {
      logout();
      setText("Du wurdest erfolgreich ausgeloggt. Bis bald...");
      setTimeout(() => navigate("/"), 1000); // Redirect to homepage after 3 sec
    });
  };

  const handleCountdown = value => {
    // console.log(countdown);
    if (value > 0) {
      setCountdown(value);
      setTimeout(() => handleCountdown(value - 1), 1000);
    } else {
      setCountdown(null);
    }
  };
  useEffect(() => {
    setButtons(<OAuthButtonGroup />);
    if (user) {
      setIsOpenLogin(true);
    }
    setCurrentPath(window.location.pathname);
  }, []);
  useEffect(() => {
    if (loginFetcher?.data) {
      // userDetails.load(`/users/${user.id}/details`);
      // console.log(loginFetcher.data);
      const response = loginFetcher.data;
      if (response.error) {
        // console.log(response.error);
        if (response.error.status == 400) {
          setError("E-Mail oder Passwort falsch!");
          handleCountdown(3);
        }
      } else if (response.authToken) {
        login({
          ...response
        });
        setSuccess("Anmeldung erfolgreich!");
        if (onCloseModal) {
          onCloseModal();
        } else {
          setTimeout(() => {
            navigate(window.location.pathname == "/login" ? "/" : window.location.pathname);
          }, 500); // Redirect to homepage after 3 sec
        }
      } else if (response.passwordReset) {
        setIsOpenReset(true);
      }
      setLoading(false);
    }
  }, [loginFetcher.data]);
  const isError = input === "";
  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema,
    onSubmit: values => {
      if (countdown && countdown > 0 || loading) {
        return;
      }
      setLoading(true);
      setError("");
      setSuccess("");
      // console.log("Form data", values);
      loginFetcher.submit(values, {
        action: `/login`,
        method: "POST"
      });
    }
  });
  function loginCenter() {
    _s();
    return <Stack spacing="8" bgColor={isModal ? "transparent" : useColorModeValue("rgba(255,255,255,0.7)", "rgba(0,0,0,0.7)")} px="3" py={isModal ? 8 : {
      base: "10",
      md: "10"
    }}
    // bg={{ base: "transparent", sm: "bg.surface" }}
    boxShadow={isModal ? 0 : {
      base: "lg",
      sm: "xl"
    }} borderRadius={{
      base: "xl",
      sm: "2xl"
    }}>

        <Stack spacing="6">
          {colorMode == "dark" && currentPath == "/login" && <DealbunnyIconNoGradient fill={"white"} minW={"100%"} minH={"40px"} mb={{
          base: "10px",
          lg: "30px"
        }} />}


          {colorMode != "dark" && currentPath == "/login" && <DealbunnyIcon fill={"white"} minW={"100%"} minH={"50px"} mb={{
          base: "10px",
          lg: "30px"
        }} />}


          <Stack spacing={{
          base: "2",
          md: "3"
        }} textAlign="center">
            <Heading size={{
            base: "md",
            md: "lg"
          }}>
              Willkommen in der dealbunny Community 🎉
            </Heading>
            <Text color="fg.muted">
              Du bist noch nicht registriert?{" "}
              <Link textDecoration={"underline"} href="/login/register">
                Hier registrieren
              </Link>
            </Text>
          </Stack>
        </Stack>
        <Box py={isModal ? {
        base: "0",
        lg: "0"
      } : {
        base: "0",
        lg: "10"
      }} px={isModal ? {
        base: "3",
        lg: "10"
      } : {
        base: "8",
        lg: "16"
      }}>

          <loginFetcher.Form method="post" onSubmit={formik.handleSubmit}>
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl isRequired isInvalid={!!(formik.touched.email && formik.errors.email)}>

                  <FormLabel htmlFor="email">E-Mail</FormLabel>
                  <Input id="email" name="email" type="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />

                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                </FormControl>
                <PasswordField name="password" id="password" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password} error={formik.touched.password && formik.errors.password ? formik.errors.password : undefined} />


              </Stack>
              <HStack justify="space-between">
                <Spacer></Spacer>
                {/* <Checkbox defaultChecked>Angemeldet bleiben</Checkbox> */}
                <Link href="/login/reset">
                  <Button variant="text" size="sm">
                    Passwort vergessen?
                  </Button>
                </Link>
              </HStack>
              <Stack spacing="6">
                <Button type="submit" disabled={loading && !!(countdown && countdown > 0)} isLoading={loading} loadingText="Anmeldung läuft" colorScheme="pink">

                  {countdown == null && "Anmelden"}
                  {!!(countdown && countdown > 0) && `Bitte warten ... ${countdown}`}
                </Button>
                {error != "" && <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>}

                {success != "" && <Alert status="success">
                    <AlertIcon />
                    {success}
                  </Alert>}

                {/* <HStack>
                   <Divider />
                   <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                     oder weiter mit
                   </Text>
                   <Divider />
                  </HStack>
                  {buttons} */}
              </Stack>
            </Stack>
          </loginFetcher.Form>
        </Box>
        <AlertDialog isOpen={isOpenReset} leastDestructiveRef={cancelRef} onClose={onClose}>

          <AlertDialogOverlay>
            <AlertDialogContent m={5}>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Willkommen auf der neue Plattform!
              </AlertDialogHeader>

              <AlertDialogBody>
                Dein altes Passwort ist leider nicht mehr gültig. Wir haben dir
                eine E-Mail geschickt, damit du dein Passwort zurücksetzen
                kannst.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button colorScheme="red" onClick={e => {
                navigate("/");
                onClose();
              }} ml={3}>

                  Startseite
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Stack>;
  }
  _s(loginCenter, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
    return [useColorModeValue];
  });
  return <>
      {isModal && loginCenter()}
      {!isModal && <>
          <Box className={currentPath == "/login" ? "loginContainer" : "loginContainer-no-background"}>


            <Container w={{
          base: "350px",
          md: "auto"
        }} maxW={{
          base: "90%",
          md: "xl"
        }}
        // py={{ base: "0", md: "24" }}
        // px={{ base: "0", md: "8" }}
        >
              {loginCenter()}
            </Container>
          </Box>
          <Modal closeOnOverlayClick={false} isOpen={isOpenLogin} onClose={onClose} isCentered>

            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Du bist bereits eingeloggt</ModalHeader>
              <ModalBody pb={6}>{text}</ModalBody>

              <ModalFooter>
                <Link href="/">
                  <Button colorScheme="pink" mr={3}>
                    Startseite
                  </Button>
                </Link>
                <Button onClick={logoutHandler}>Logout</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>}

    </>;
};
_s2(Login, "iOwAF5qzBZGkrchRcprdj6iHfnE=", false, function () {
  return [useAuth, useDisclosure, useNavigate, useColorMode, useFetcher, useFormik];
});
_c = Login;
export const LoginPopup = props => {
  _s3();
  const {
    isOpen,
    onClose,
    onOpen
  } = useDisclosure();
  const {
    user,
    checkUsershowLogin
  } = useAuth();
  const {
    text
  } = props;
  useEffect(() => {
    !user && onOpen();
  }, []);
  useEffect(() => {
    if (user) {
      onClose();
    }
  }, [user]);
  return <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Du bist nicht angemeldet.</ModalHeader>
        <ModalBody pb={6}>{text}</ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={e => {
          onClose();
        }}>

            Schließen
          </Button>

          <Button colorScheme="pink" onClick={e => {
          checkUsershowLogin();
        }}>

            Login
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>;
};
_s3(LoginPopup, "tNDeLj47L/3waOoJmM/0DnUn3UQ=", false, function () {
  return [useDisclosure, useAuth];
});
_c2 = LoginPopup;
var _c, _c2;
$RefreshReg$(_c, "Login");
$RefreshReg$(_c2, "LoginPopup");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;